const tic_tac_toe = () => {
  return (
    <svg
      enableBackground='new 0 0 24 24'
      id='Layer_1'
      version='1.1'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'>
      <g>
        <path
          className='logo'
          d='M12,14.5c1.3789063,0,2.5-1.1210938,2.5-2.5S13.3789063,9.5,12,9.5S9.5,10.6210938,9.5,12   S10.6210938,14.5,12,14.5z M12,11.5c0.2753906,0,0.5,0.2246094,0.5,0.5s-0.2246094,0.5-0.5,0.5s-0.5-0.2246094-0.5-0.5   S11.7246094,11.5,12,11.5z'
          fill='#303C42'
        />
        <path
          className='logo'
          d='M1.2929688,5.7070313C1.4882813,5.9023438,1.7441406,6,2,6s0.5117188-0.0976563,0.7070313-0.2929688   L3.5,4.9140625l0.7929688,0.7929688C4.4882813,5.9023438,4.7441406,6,5,6s0.5117188-0.0976563,0.7070313-0.2929688   c0.390625-0.390625,0.390625-1.0234375,0-1.4140625L4.9140625,3.5l0.7929688-0.7929688   c0.390625-0.390625,0.390625-1.0234375,0-1.4140625s-1.0234375-0.390625-1.4140625,0L3.5,2.0859375L2.7070313,1.2929688   c-0.390625-0.390625-1.0234375-0.390625-1.4140625,0s-0.390625,1.0234375,0,1.4140625L2.0859375,3.5L1.2929688,4.2929688   C0.9023438,4.6835938,0.9023438,5.3164063,1.2929688,5.7070313z'
          fill='#303C42'
        />
        <path
          className='logo'
          d='M22.7070313,18.2929688c-0.390625-0.390625-1.0234375-0.390625-1.4140625,0L20.5,19.0859375   l-0.7929688-0.7929688c-0.390625-0.390625-1.0234375-0.390625-1.4140625,0s-0.390625,1.0234375,0,1.4140625L19.0859375,20.5   l-0.7929688,0.7929688c-0.390625,0.390625-0.390625,1.0234375,0,1.4140625C18.4882813,22.9023438,18.7441406,23,19,23   s0.5117188-0.0976563,0.7070313-0.2929688L20.5,21.9140625l0.7929688,0.7929688C21.4882813,22.9023438,21.7441406,23,22,23   s0.5117188-0.0976563,0.7070313-0.2929688c0.390625-0.390625,0.390625-1.0234375,0-1.4140625L21.9140625,20.5l0.7929688-0.7929688   C23.0976563,19.3164063,23.0976563,18.6835938,22.7070313,18.2929688z'
          fill='#303C42'
        />
        <path
          className='logo'
          d='M23.5,9C23.7763672,9,24,8.7763672,24,8.5v-1C24,7.2236328,23.7763672,7,23.5,7H17V0.5   C17,0.2236328,16.7763672,0,16.5,0h-1C15.2236328,0,15,0.2236328,15,0.5V7H9V0.5C9,0.2236328,8.7763672,0,8.5,0h-1   C7.2236328,0,7,0.2236328,7,0.5V7H0.5C0.2236328,7,0,7.2236328,0,7.5v1C0,8.7763672,0.2236328,9,0.5,9H7v6H0.5   C0.2236328,15,0,15.2236328,0,15.5v1C0,16.7763672,0.2236328,17,0.5,17H7v6.5C7,23.7763672,7.2236328,24,7.5,24h1   C8.7763672,24,9,23.7763672,9,23.5V17h6v6.5c0,0.2763672,0.2236328,0.5,0.5,0.5h1c0.2763672,0,0.5-0.2236328,0.5-0.5V17h6.5   c0.2763672,0,0.5-0.2236328,0.5-0.5v-1c0-0.2763672-0.2236328-0.5-0.5-0.5H17V9H23.5z M15,15H9V9h6V15z'
          fill='#303C42'
        />
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  );
};

export default tic_tac_toe;
